import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { TextMuted } from "../../../components/Text";
import {
  verifyAndInviteUser,
  registerUser,
  putUser,
  revokeAccess,
} from "../../../services/api/admin";
import { getContracts } from "../../../services/api/contract";
import {
  ButtonInsert,
  ButtonOption,
  ButtonSave,
  ListButtonRow,
  ButtonDelete,
} from "../../../components/Buttons";
import {
  InputColumn,
  InputText,
  InputCheckbox,
  InputPassword,
  InputValidation,
} from "../../../components/Inputs";
import { HeaderTitle } from "../../../components/Headers";
import {
  SelectContract,
  SelectGroupClient,
  SelectGroup,
} from "../../../components/Selects";
import { Modal } from "../../../components/Modal";

interface Props {
  user?: User;
  created: (user: User) => void;
  updated: (user: User) => void;
  deleted: (user: User) => void;
}

type REGISTER_STEPS = "start" | "checkUser" | "registerUser" | "editUser";

const UserForm: React.FC<Props> = (props) => {
  const [registerStep, setRegisterStep] = useState<REGISTER_STEPS>("start");
  const [validForm, setValidForm] = useState<boolean>(false);
  const [validateUser, setValidateUser] = useState<string>("");
  const [userRoles, setUserRoles] = useState<string>("");
  const [email, setEmail] = useState("");
  const [toggleModal, setToggleModal] = useState(false);

  useEffect(() => {
    if (props.user === undefined) {
      setRegisterStep("start");
    } else {
      setRegisterStep("editUser");
      if (props.user.groups.length === 1)
        setUserRoles(props.user.groups[0].name);
      else setUserRoles("");
    }
  }, [props.user]);

  const modalAlert = () => (
    <Modal
      title=""
      toggle={() => setToggleModal(!toggleModal)}
      isOpen={toggleModal}
      size="md"
      backdrop
      className=""
    >
      <Row className="text-center align-items-center">
        <Col>
          <h2 className="swal2-title" id="swal2-title">
            Acesso concedido
          </h2>
          <div id="swal2-content">
            O usuário <strong>{email}</strong> foi ativado para esta aplicação.
          </div>
          <div id="swal2-content">
            Um email para dar a boa notícia foi enviado.
          </div>
          <div className="mt-3">
            <ButtonInsert onClick={() => setToggleModal(false)}>
              Fechar
            </ButtonInsert>
          </div>
        </Col>
      </Row>
    </Modal>
  );

  const checkUser = () => {
    const sendInvitation = (values: { email: string }) => {
      setEmail(values.email);
      verifyAndInviteUser(values.email)
        .then(({ data }) => {
          props.created(data);
          setToggleModal(true);
          setRegisterStep("start");
        })
        .catch((res) => {
          if (res.data.code === 0) {
            setRegisterStep("registerUser");
          } else {
            setValidateUser("Usuário já adicionado a esta aplicação");
          }
        });
    };

    return (
      <>
        <Row className="text-center align-items-center">
          <Col className="align-middle">
            <TextMuted>
              Primeiro precisamos do email para verificar se o usuário já existe
            </TextMuted>
          </Col>
        </Row>
        <Row>
          <Col>
            <Formik
              onSubmit={sendInvitation}
              initialValues={{ email: "" }}
              render={() => (
                <Form>
                  <InputColumn label="Email">
                    <Field
                      name="email"
                      type="email"
                      required
                      component={InputText}
                    />
                    <InputValidation message={validateUser} />
                  </InputColumn>
                  <ButtonOption type="submit">Próximo</ButtonOption>
                </Form>
              )}
            />
          </Col>
        </Row>
      </>
    );
  };

  const isTechnician = (role: string) => {
    return (
      role === "technician" ||
      role === "technician_soil" ||
      role === "technician_concrete"
    );
  };

  const checkPassword = (value: string) => {
    const passwordRegex =
      /^(?=.*[a-z])*(?=.*[A-Z])*(?=.*\d)*(?=.*[#$^+=!*()@%&])*.{6,10}$/g;
    if (value.match(passwordRegex) === null) {
      return "A senha deve ter de 6 a 10 caracteres.";
    }

    return null;
  };

  const comparePassword = (password?: string, confirmPassword?: string) => {
    if (
      confirmPassword === undefined ||
      password === undefined ||
      password !== confirmPassword
    ) {
      setValidForm(false);
      return "As senhas são diferentes";
    }
    setValidForm(true);

    return null;
  };

  const userRole = () => {
    if (props.user !== undefined)
      return props.user.groups.length === 1
        ? props.user.groups[0].name || ""
        : "";

    return "";
  };

  const removeUserAccess = () => {
    if (props.user)
      return revokeAccess({ id: props.user.id })
        .then(({ data }) => {
          props.deleted(data);
          setRegisterStep("start");
          return Promise.resolve(data);
        })
        .catch((res) => {
          return Promise.reject(res);
        });

    return Promise.reject();
  };

  const form = () => {
    return (
      <Formik
        onSubmit={(value: User, { setSubmitting }) => {
          if (!value.id) {
            delete value.password_confirm;
            registerUser(value).then(({ data }: { data: User }) => {
              props.created(data);
              setSubmitting(false);
              setRegisterStep("start");
            });
          } else {
            putUser(value.id, value).then(({ data }) => {
              props.updated(data);
              setSubmitting(false);
              setRegisterStep("start");
            });
          }
        }}
        initialValues={{
          // @ts-ignore
          email,
          // @ts-ignore
          role: userRole(),
          ...(props.user as User),
        }}
        enableReinitialize
        render={({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <Row>
              <Col>
                <HeaderTitle
                  title={`${
                    props.user !== undefined ? "Editar" : "Cadastrar"
                  } acesso de usuário`}
                />
                {props.user === undefined && (
                  <>
                    <InputColumn label="Email">
                      <Field component={InputText} name="email" disabled />
                    </InputColumn>
                    <InputColumn label="Primeiro nome">
                      <Field component={InputText} name="first_name" />
                    </InputColumn>
                    <InputColumn label="Último nome">
                      <Field component={InputText} name="last_name" />
                    </InputColumn>
                    <InputColumn label="Senha">
                      <Field component={InputPassword} name="password" />
                      <InputValidation
                        message={checkPassword(values.password || "")}
                      />
                    </InputColumn>
                    <InputColumn label="Confirmar senha">
                      <Field
                        component={InputPassword}
                        name="password_confirm"
                      />
                      <InputValidation
                        message={comparePassword(
                          values.password,
                          values.password_confirm,
                        )}
                      />
                    </InputColumn>
                  </>
                )}
                <InputColumn>
                  <InputCheckbox
                    label="Usuário ativo"
                    name="is_active"
                    checked={values.is_active}
                    onClick={() =>
                      setFieldValue("is_active", !values.is_active)
                    }
                  />
                </InputColumn>
                <InputColumn>
                  <InputCheckbox
                    label="O usuário é um cliente"
                    name="is_client"
                    checked={values.is_client}
                    onClick={() =>
                      setFieldValue("is_client", !values.is_client)
                    }
                  />
                </InputColumn>
                <InputColumn label="Nível de acesso">
                  {values.is_client ? (
                    <Field component={SelectGroupClient} name="role" required />
                  ) : (
                    <Field component={SelectGroup} name="role" required />
                  )}
                </InputColumn>
                <InputColumn>
                  <InputCheckbox
                    label="Laboratorista em obra"
                    name="is_lab_in_work"
                    checked={values.is_lab_in_work && isTechnician(values.role)}
                    onClick={() =>
                      setFieldValue("is_lab_in_work", !values.is_lab_in_work)
                    }
                  />
                </InputColumn>
                <InputColumn label="Contratos para acesso">
                  <Field
                    component={SelectContract}
                    name="contracts"
                    isMulti
                    client={1}
                    request={() =>
                      getContracts(
                        "",
                        "",
                        "",
                        "id,work",
                        userRoles.split("_")[1],
                        "1",
                        "",
                      )
                    }
                    group={userRoles.split("_")[1]}
                    disabled={!(values.is_lab_in_work || values.is_client)}
                  />
                </InputColumn>
                <ListButtonRow
                  buttonsLeft={
                    <ButtonSave
                      disabled={props.user === undefined && !validForm}
                      isSubmitting={isSubmitting}
                    >
                      Salvar
                    </ButtonSave>
                  }
                  buttonsRight={
                    <ButtonDelete
                      disabled={!props.user}
                      onClick={removeUserAccess}
                    />
                  }
                />
              </Col>
            </Row>
          </Form>
        )}
      />
    );
  };

  const userSteps = () => {
    if (registerStep === "start") {
      return (
        <>
          <Row className="text-center align-items-center">
            <Col className="align-middle">
              <TextMuted>
                Para editar, um funcionário deve ser selecionado
              </TextMuted>
            </Col>
          </Row>
          <Row className="text-center align-items-center">
            <Col className="align-middle">
              <ButtonInsert onClick={() => setRegisterStep("checkUser")}>
                Novo usuário
              </ButtonInsert>
            </Col>
          </Row>
        </>
      );
    }
    if (registerStep === "checkUser") {
      return checkUser();
    }
    if (registerStep === "registerUser" || registerStep === "editUser") {
      return form();
    }

    return null;
  };

  return (
    <>
      {modalAlert()}
      {userSteps()}
    </>
  );
};

export default UserForm;
